import dynamic from 'next/dynamic';
var DynamicShareComponent = dynamic(function () {
  return import('./ShareComponentBase');
}, {
  ssr: false,
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak('./ShareComponentBase')];
    },
    modules: ["../components/commons/ShareComponent.tsx -> " + './ShareComponentBase']
  }
});
export default DynamicShareComponent;