import React, { FC, useMemo } from 'react';
import BriefSkeleton from './BriefSkeleton';

interface NoItemsProps {
  type?: 'briefs' | 'channels';
  children?: any;
}
const NoItems: FC<NoItemsProps> = ({ type = 'channels', children }) => {

  const ownChannel = false;
  const text = useMemo(() => {
    if (type === 'briefs')
      return `There are no briefs in ${ownChannel ? 'your' : 'this'} channel yet`;
    if (type === 'channels')
      return `There are no channels in ${ownChannel ? 'your' : 'this'} profile yet`;
  }, [type]);

  return (
    <div className="noItemsFound">
      {/* <div>
        <h3>{text}</h3>
      </div> */}

      <BriefSkeleton />
      <BriefSkeleton />
      <BriefSkeleton />

      {/* {ownChannel && children} */}
    </div>
  );
};
export default React.memo(NoItems);
